import { ref, onBeforeUnmount } from 'vue'

const observer = ref(null)
const targets = ref([])
const targetsVisible = ref([])
const targetRects = ref([])

const { innerHeight } = useClientState()

function useIntersectionObserver() {
    const visible = ref(false)
    const rect = ref({})
    let targetElement = null

    function buildObserver(height) {
        const thresholdArray = new Array(height + 1).fill(0).map((zero, index) => index * (1 / (height + 1)))
        observer.value = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const targetIndex = targets.value.findIndex(t => t.value === entry.target)
                    if (targetIndex !== -1) {
                        targetsVisible.value[targetIndex].value = entry.intersectionRatio > 0
                        targetRects.value[targetIndex].value = entry.boundingClientRect
                    }
                })
            },
            {
                threshold: thresholdArray,
            },
        )
    }

    function rebuildObserver(height) {
        if (observer.value) {
            if (targetElement?.value) {
                observer.value.unobserve(targetElement.value)
            }

            observer.value.disconnect()
            observer.value = null
        }
        buildObserver(height)
        for (let i = 0, il = targets.value.length; i < il; ++i) {
            const target = targets.value[i]
            observer.value.observe(target.value)
        }
    }

    if (!isServer() && !observer.value) {
        buildObserver(innerHeight.value)
        const debouncedObserverRebuild = debounce(rebuildObserver, 250)
        watch(innerHeight, () => {
            debouncedObserverRebuild(innerHeight.value)
        })
    }

    function observe(elRef) {
        if (!isServer()) {
            targetElement = elRef
            targets.value.push(elRef)
            targetsVisible.value.push(visible)
            targetRects.value.push(rect)

            return { visible, rect }
        }

        return { visible, rect }
    }

    onMounted(() => {
        if (!isServer()) {
            observer.value.observe(targetElement.value)
        }
    })

    onBeforeUnmount(() => {
        if (!isServer()) {
            observer.value.unobserve(targetElement.value)
            const targetIndex = targets.value.findIndex(t => t.value === targetElement.value)
            targets.value.splice(targetIndex, 1)
            targetsVisible.value.splice(targetIndex, 1)
            targetRects.value.splice(targetIndex, 1)
        }
    })

    return { observer, observe }
}

export { useIntersectionObserver }
