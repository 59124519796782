<script setup>
import Tempus from '@darkroom.engineering/tempus'

const { innerHeight } = useClientState()
const { observe } = useIntersectionObserver()

const emit = defineEmits(['enter', 'leave', 'progress', 'lerp'])

const props = defineProps({
    offset: { default: 0, type: Number },
    lerp: { type: Number, required: false, default: 0 },
})
const root = ref(null)
const progress = ref(0)
const prevProgress = ref(-1)
const { visible } = observe(root)

provide('visibility', {
    visible,
    progress,
    root,
})

const render = () => {
    if (!visible.value || !root?.value) return

    const rect = root.value.getBoundingClientRect()
    const elementTop = rect.y + rect.height
    const windowHeight = innerHeight.value + rect.height

    progress.value = 1 - (elementTop / windowHeight) - props.offset
    if (progress.value < -props.offset) progress.value = -props.offset
}
const debouncedRender = debounce(render, 250)

let removeRaf = () => {}
let removeRafVisible = () => {}

const unwatch = watch(visible, (val) => {
    if (val) {
        emit('enter', root.value)
        removeRafVisible = Tempus.add(render, 0)
    }
    else {
        emit('leave', root.value)
        removeRafVisible()
    }
})

onMounted(() => {
    if (!isServer()) {
        let once = false
        // Rendering in a requestAnimationFrame instead of the scroll handler
        removeRaf = Tempus.add(() => {
            if (!once) {
                once = true
            }
            if (prevProgress.value !== progress.value) {
                emit('progress', progress.value)
                prevProgress.value = progress.value
            }
        }, 999)

        nextTick(render)
        window.addEventListener('orientationchange', debouncedRender)
        window.addEventListener('resize', debouncedRender)
    }
})

onBeforeUnmount(() => {
    if (!isServer()) {
        removeRaf()
        removeRafVisible()
        window.removeEventListener('orientationchange', debouncedRender)
        window.removeEventListener('resize', debouncedRender)
        unwatch()
    }
})
</script>

<template>
    <div
        ref="root"
        class="VisibleProgress"
    >
        <slot
            :visible="visible"
            :progress="progress"
        />
    </div>
</template>
